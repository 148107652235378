import { convertToJson } from 'fast-xml-parser';
import React, { Component } from 'react';
import { Input, CustomInput, Row, Col, Card, CardTitle, Label, Button, Form, InputGroupText, InputGroupAddon } from 'reactstrap';
import helper from "../services/helper";
import moment from 'moment'

class PointMonthConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            schema: props.schema,
            dataPoint: [],
            dataPercent: [],
            type: "",
            status: false,
            startTime:"",
            endTime:"",
            // value: props.value ? moment(props.value).toDate() : new Date(),
            focus: false
        };
    }


    componentDidMount() {
        fetch(`http://dev-bibica.mpointshop.vn/api/admin/config/point-order-month`, {
            headers: {
                "Authorization": "Basic 7751d890-e8e7-4611-88c9-2bc6c569db45"
            }
        })
            .then(response => response.json())
            .then(res => {
                let dataPoint = []
                let dataPercent = []
                for (let property in res.data.priceList.point) {
                    dataPoint.push({ money: property, point: res.data.priceList.point[property] })
                }
                for (let property in res.data.priceList.percent) {
                    dataPercent.push({ money: property, percent: res.data.priceList.percent[property] })
                }

                this.setState(
                    {
                        dataPercent: dataPercent,
                        dataPoint: dataPoint,
                        type: res.data.type,
                        status: res.data.priceList.isActive,
                        startTime: moment.unix(res.data.priceList.startTime/1000).format("YYYY-MM-DD"),
                        endTime: moment.unix(res.data.priceList.endTime / 1000).format("YYYY-MM-DD")

                    })
            })
    }
    // onChange(val) {
    //     this.setState({ value: val });
    //     if (this.props.onChange) {
    //         if (val) {
    //             this.props.onChange(val.valueOf());
    //         } else {
    //             this.props.onChange(val);
    //         }

    //     }
    // }
    // componentWillReceiveProps(next) {
    //     if (next.value !== moment(this.state.value).valueOf()) {
    //         this.setState({ value: next.value ? moment(next.value).toDate() : new Date() });
    //     }
    // }
    onChange = (type, index, field, value) => {
        console.log("$$alo", type, index, field, value)
        if (type === "point") {
            this.setState({
                dataPoint:
                    this.state.dataPoint.map((item, i) => {
                        if (i === index) {
                            if (field === "money") {
                                value = value.toString()
                            }
                            return { ...item, [field]: value }
                        }
                        return { ...item }
                    })
            })
        }
        if (type === "percent") {
            this.setState({
                dataPercent:
                    this.state.dataPercent.map((item, i) => {
                        if (i === index) {
                            if (field === "money") {
                                value = value.toString()
                            }
                            return { ...item, [field]: value }
                        }
                        return { ...item }
                    })
            })
        }
    }
    onSubmit = () => {
        let body = {
            type: this.state.type,
            priceList: {}
        }
        let point = {}
        this.state.dataPoint.map(item => {
            point[item.money] = item.point
        })
        let percent = {}
        this.state.dataPercent.map(item => {
            percent[item.money] = item.percent
        })
        body.priceList.isActive = this.state.status
        body.priceList.startTime = new Date(this.state.startTime).getTime()
        body.priceList.endTime = new Date(this.state.endTime).getTime()
        body.priceList.percent = percent
        body.priceList.point = point
        body.priceList = JSON.stringify(body.priceList)


        fetch(`http://dev-bibica.mpointshop.vn/api/admin/config/update-point-order-month`,
            {
                headers: {
                    "Authorization": "Basic 7751d890-e8e7-4611-88c9-2bc6c569db45"
                },
                method: "POST",
                body: JSON.stringify(body)
            })
            .then(res => {
                helper.alert("Thành công")
            })
            .catch(err => { helper.alert("Thất bại Lỗi ", err) })
    }
    render() {
        console.log("$$alo ", this.state.status)
        return (<>
            <Form onSubmit={this.onSubmit} >
                <Card style={{ border: 0 }}>
                    <CardTitle style={{ fontWeight: "bolder", color: "#f86c6b" }} tag="h5">Thông tin chung</CardTitle>
                    <Row>
                        <Col xs="6">
                            <InputGroupAddon style={{ marginLeft: -20 }} addonType="prepend">
                                <InputGroupText>Loại điểm</InputGroupText>
                                <Input
                                    type="select"
                                    value={this.state.type}
                                    name="type"
                                    onChange={evt => {
                                        this.setState({ type: evt.target.value })
                                        // if (this.props.onChange) {
                                        //     this.props.onChange(evt.target.value);
                                        // }
                                    }} >
                                    <option key="point" value='point'>Điểm</option>
                                    <option key="percent" value='percent'>Phần trăm</option>
                                </Input>
                            </InputGroupAddon>
                        </Col>
                        <Col xs="6">
                            <InputGroupAddon className="c-build-activebtn" style={{ marginLeft: -20 }} addonType="prepend">
                                <InputGroupText>Kích hoạt</InputGroupText>
                                <CustomInput
                                checked={this.state.status}
                                    onChange={evt => {
                                        this.setState({ status: evt.target.checked})
                                    }}
                                type="switch" id="exampleCustomSwitch" name="customSwitch" />
                            </InputGroupAddon>
                        </Col>


                    </Row>
                </Card>
                <Card style={{ border: 0, marginTop: 20 }}>
                    <CardTitle style={{ fontWeight: "bolder", color: "#f86c6b" }} tag="h5" >Khoảng thời gian hoạt động</CardTitle>
                    <Row style={{ width: "99%", marginTop: 30, border: "1px solid", color: "#bdc7c0" }}>
                        <Col xs="6">
                            <InputGroupAddon style={{ marginLeft: -20 }} addonType="prepend">
                                <InputGroupText>Ngày bắt đầu</InputGroupText>
                                <Input
                                    type="date"
                                    style={{ border: "none" }}
                                    placeholder={this.props.schema.placeholder}
                                    name={`startTime`}
                                    defaultValue={this.state.startTime}
                                    onChange={evt => {
                                        this.setState({ startTime: evt.target.value })
                                      
                                    }} />
                            </InputGroupAddon>

                        </Col>
                        <Col xs="6">
                            <InputGroupAddon style={{ marginRight: 100 }} addonType="prepend">
                                <InputGroupText>Ngày kết thúc</InputGroupText>
                                <Input
                                    style={{ border: "none" }}
                                    type="date"
                                    // type={type}
                                    name={`endTime`}
                                    placeholder={this.props.schema.placeholder}
                                    defaultValue={this.state.endTime}
                                    onChange={evt => {
                                        this.setState({ endTime: evt.target.value })
                                        // if (this.props.onChange) {
                                        //     this.props.onChange(evt.target.value);
                                        // }
                                    }} />
                            </InputGroupAddon>
                        </Col>
                    </Row>
                </Card>
                <Card style={{ border: 0, marginTop: 20 }}>
                    <CardTitle style={{ fontWeight: "bolder", color: "#f86c6b" }} tag="h5" >Tích điểm cố định</CardTitle>
                    {this.state.dataPoint.map((item, index) =>
                        <Row style={{ width: "99%", marginTop: 30, border: "1px solid", color: "#bdc7c0" }}>
                            <Col xs="6">
                                <InputGroupAddon style={{ marginLeft: -20 }} addonType="prepend">
                                    <InputGroupText>Mốc tiền</InputGroupText>
                                    <Input
                                        style={{ border: "none" }}
                                        placeholder={this.props.schema.placeholder}
                                        name={`point.${index}.money`}
                                        defaultValue={item.money}
                                        onChange={e => {
                                            this.onChange("point", index, "money", e.target.value)
                                            // if (this.props.onChange) {
                                            //     this.props.onChange(evt.target.value);
                                            // }
                                        }} />
                                </InputGroupAddon>

                            </Col>
                            <Col xs="6">
                                <InputGroupAddon style={{ marginRight: 200 }} addonType="prepend">
                                    <InputGroupText>Mốc điểm</InputGroupText>
                                    <Input
                                        style={{ border: "none" }}
                                        // type={type}
                                        name={`point.${index}.point`}
                                        placeholder={this.props.schema.placeholder}
                                        defaultValue={item.point}
                                        onChange={e => {
                                            this.onChange("point", index, "point", +e.target.value)
                                            // if (this.props.onChange) {
                                            //     this.props.onChange(evt.target.value);
                                            // }
                                        }} />
                                </InputGroupAddon>
                            </Col>
                        </Row>
                    )}


                </Card>

                <Card style={{ border: 0 }}>
                    <CardTitle style={{ fontWeight: "bolder", color: "#f86c6b " }} tag="h5">Tích điểm theo phần trăm</CardTitle>
                    {this.state.dataPercent.map((item, index) =>
                    (<Row style={{ width: "99%", marginTop: 30, border: "1px solid", color: "#bdc7c0" }}>
                        <Col xs="6">
                            <InputGroupAddon style={{ marginLeft: -20 }} addonType="prepend">
                                <InputGroupText>Mốc tiền</InputGroupText>
                                <Input
                                    style={{ border: "none" }}
                                    disabled={this.props.disabled}
                                    // type={type}
                                    name={`percent.${index}.money`}
                                    placeholder={this.props.schema.placeholder}
                                    defaultValue={item.money}
                                    onChange={e => {
                                        this.onChange("percent", index, "money", e.target.value)
                                    }} />
                            </InputGroupAddon>

                        </Col>
                        <Col xs="6">
                            <InputGroupAddon style={{ marginRight: 200 }} addonType="prepend">
                                <InputGroupText >Mốc phần trăm</InputGroupText>
                                <Input
                                    style={{ border: "none" }}
                                    // type={type}
                                    placeholder={this.props.schema.placeholder}
                                    name={`percent.${index}.percent`}
                                    defaultValue={item.percent}
                                    name={`percent.${index}`}
                                    onChange={e => {
                                        this.onChange("percent", index, "percent", +e.target.value)
                                        // if (this.props.onChange) {
                                        //     this.props.onChange(evt.target.value);
                                        // }
                                    }}
                                />
                            </InputGroupAddon>

                        </Col>
                    </Row>
                    )

                    )}
                </Card>
                <Button color="primary" type="submit" style={{ float: "right" }}>Cập nhật</Button>
            </Form>

        </>)
    }
}

export default PointMonthConfig;