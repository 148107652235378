import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
// import { TinyMCE as TinyMCEGlobal } from 'tinymce/';
import tinymce from 'tinymce/tinymce';
import _ from 'lodash';
import FileManager from './Image1';
import { Modal } from 'antd';

// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';
// A theme is also required
import 'tinymce/themes/silver';
// Any plugins you want to use has to be imported
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/code';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/wordcount';

window.tinymce = tinymce;
const RichTextEditor = ({ value, onChange, style, mode = "simple" }) => {
  const [text, setText] = useState(value || '');
  const [fmVisible, setFmVisible] = useState(false);
  const [isVal, setIsVal] = useState(false);
  const [images, setImages] = useState([]);

  // useEffect(() => {
  //   setText(value || '');
  // }, [value]);

  const showFileManager = (cb) => {
    setFmVisible(true);
    window.tinycmeCallback = cb;
  };

  const hideFileManager = (e) => {
    setFmVisible(false);
  };

  const handleChange = (val) => {
    let arrImg = _.cloneDeep(images);
    if (mode === "multiple") {
      arrImg = _.concat(arrImg, val);
    } else {
      arrImg = _.concat([], ((val || [])[0] || []));
    }
    console.log('2.handleChange -> arrImg', arrImg);
    setImages(arrImg);
    setIsVal(false);
    if (window.tinycmeCallback) {
      window.tinycmeCallback(`${val}`);
    }
  };
  // const memoChange = useMemo((val) => handleChange(val), [fmVisible]);

  const handleOk = () => {
    setFmVisible(false);
    setIsVal(true);
  };

  const onEditorChange = React.useCallback((e) => {
    const val = e.target.getContent();
    console.log('RichTextEditor -> val', val);
    // setText(val);
    if (onChange) {
      onChange(val);
    }
  }, []);

  return (
    <>
      <Editor
        // tinymceScriptSrc="https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js"
        apiKey="cswezi492a2ax6zazo549bldqfazwrgrslsurvl1caolgntp"
        style={{ height: '250px', minHeight: '250px', marginBottom: '50px', ...style }}
        initialValue={text}
        init={{
          height: 300,
          plugins: [
            "code advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen textcolor",
            "insertdatetime media table contextmenu paste imagetools wordcount"
          ],
          toolbar: "insertfile undo redo | styleselect forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code",
          extended_valid_elements: 'span[id|style|class]',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          // automatic_uploads: false,
          convert_urls: false,
          noneditable_noneditable_class: 'mceNonEditable',
          file_picker_types: 'image',
          file_picker_callback: function (callback, value, meta) {
            console.log('RichTextEditor file_picker_callback-> meta', meta);
            console.log('RichTextEditor file_picker_callback-> value', value);
            showFileManager(callback);
            // Provide file and text for the link dialog
            // if (meta.filetype == 'file') {
            // 	callback(images[0].path, { text: images[0].name });
            // 	console.log('RichTextEditor -> file_picker_callback images', images);
            // }

            // // Provide image and alt text for the image dialog
            // if (meta.filetype == 'image') {
            // 	callback(images[0].path, { text: images[0].name });
            // 	console.log('RichTextEditor -> file_picker_callback images', images);
            // }

            // // Provide alternative source and posted for the media dialog
            // if (meta.filetype == 'media') {
            // 	// callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' });
            // }
          }
        }}
        onChange={onEditorChange}
      />
      <Modal
        // className={styles.fmModal}
        style={{ top: "50px" }}
        width={500}
        title="Quản lý file"
        visible={fmVisible}
        onOk={handleOk}
        onCancel={hideFileManager}
        destroyOnClose={true}
      >
        <FileManager onChange={handleChange} isVal={isVal} />
      </Modal>
    </>
  );
};

export default RichTextEditor;
